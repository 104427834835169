import IFormConfigHandler from '@/module/product/view/ProductFormPage/handler/contract/IFormConfigHandler';
import IFormConfigBlock from '@/module/design-system/components/models/FormComponent/IFormConfigBlock';
import EFieldName from '@/module/product/view/ProductFormPage/model/EFieldName';
import IServiceContainer from '@/module/common/service/ServiceContainer/IServiceContainer';
import EFormFieldType from '@/module/design-system/components/models/FormComponent/EFormFieldType';
import ELicenseType from '@/module/product/model/Product/Fields/LicenseType/ELicenseType';
import EValidationFieldStatusCode from '@/module/common/components/FormComponent/EValidationFieldStatusCode';
import EAvailableFor from '@/module/product/model/Product/Fields/AvailableFor/EAvailableFor';
import ICInputWithSelectListValueConfig
  from '@/module/common/models/UI/CInputWithSelectList/ICInputWithSelectListValueConfig';
import ECalendarSymbol from '@/module/product/model/ECalendarSymbol';
import { EConditionType } from '@/module/common/helpers/checkCondition';
import EPricingModelName from '@/module/product/model/Product/Fields/PricingModel/EPricingModelName';
import TPricingModelOption from '@/module/product/model/Product/Fields/PricingModel/Option/TPricingModelOption';
import EPriceType from '@/module/product/model/EPriceType';
import EColorScheme from '@/module/design-system/components/models/EColorScheme';
import ECInputWithSelectMode from '@/module/common/models/ECInputWithSelectMode';

class FormConfigHandler implements IFormConfigHandler {
  private serviceContainer: IServiceContainer;

  constructor(serviceContainer: IServiceContainer) {
    this.serviceContainer = serviceContainer;
  }

  get = (): IFormConfigBlock<EFieldName, undefined>[] => {
    const localisationRegistry = this.serviceContainer.localisationRegistry;
    const moduleProduct = this.serviceContainer.moduleProduct;
    const numberFormatService = this.serviceContainer.numberFormatService;
    const productFormPageHandler = this.serviceContainer.portalModeService
      .getProductFormPageHandler();
    const state = moduleProduct.productFormPage.getState();
    const availableForService = moduleProduct.availableForService;
    const licenseTypeService = moduleProduct.licenseTypeService;
    const currencyList = productFormPageHandler.getPriceCurrencyList()
      .map((currency) => ({ id: currency, title: currency.toUpperCase() }));

    const formData = moduleProduct.productFormPage.handlerService.formDataHandler.get();

    const delimiter = numberFormatService.getMoneyDelimiter();

    const freeTrialPeriodHint = state.isEditMode ? [
      {
        id: 2,
        type: EFormFieldType.INFO_BLOCK,
        fieldName: EFieldName.TITLE,
        fieldBlock: undefined,
        config: {
          text: localisationRegistry.productsOld.form.freeTrialPeriod.hint,
          variant: EColorScheme.PRIMARY,
          disableIcon: true,
          allowHtml: true,
        },
      },
    ] : [];

    return [
      {
        header: localisationRegistry.productsOld.form.details,
        items: [
          {
            id: 1,
            type: EFormFieldType.BUTTON_GROUP,
            fieldName: EFieldName.LICENSE_TYPE,
            fieldBlock: undefined,
            buttonList: licenseTypeService.list(),
            tooltip: localisationRegistry.productsOld.form.productType.tooltip,
            variant: 'stuck',
            default: licenseTypeService.get(ELicenseType.NEW),
          },
          {
            id: 2,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.title,
            placeholder: localisationRegistry.productsOld.form.titlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.title,
            status: state.statuses[EFieldName.TITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 3,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.SUBTITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.subtitle,
            placeholder: localisationRegistry.productsOld.form.subtitlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.subtitle,
            status: state.statuses[EFieldName.SUBTITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 4,
            type: EFormFieldType.TEXT_AREA,
            fieldName: EFieldName.DESCRIPTION,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.description,
            placeholder: localisationRegistry.productsOld.form.descriptionPlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.description,
            status: state.statuses[EFieldName.DESCRIPTION] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 5,
            type: EFormFieldType.BUTTON_GROUP,
            fieldName: EFieldName.AVAILABLE_FOR,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.availableFor.label,
            tooltip: localisationRegistry.productsOld.form.availableFor.tooltip,
            buttonList: availableForService.list(),
            variant: 'spaced',
            default: availableForService.get(EAvailableFor.ALL),
          },
          {
            id: 6,
            type: EFormFieldType.SWITCHER,
            fieldName: EFieldName.ENABLE_LICENSE_TERM,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.termButton,
            tooltip: localisationRegistry.tooltip.product.form.term,
            switcher: {
              revert: true,
            },
            default: false,
          },
          {
            id: 7,
            type: EFormFieldType.INPUT_WITH_SELECT,
            fieldName: EFieldName.LICENSE_TERM,
            fieldBlock: undefined,
            config: this.getParamsConfig(formData.licenseTerm?.inputValue ?? 1, EFieldName.LICENSE_TERM),
            default: {
              inputValue: 1,
              selectValue: moduleProduct.termService.list(1)
                .find((item) => item.id === ECalendarSymbol.M),
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.ENABLE_LICENSE_TERM,
                value: true,
              },
            ],
          },
        ],
      },
      {
        header: localisationRegistry.productsOld.form.pricing,
        items: [
          {
            id: 1,
            type: EFormFieldType.SELECT,
            fieldName: EFieldName.PRICING_MODEL,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.pricingModel,
            select: {
              idField: 'id',
              titleField: 'title',
              list: this.getAllowedPriceModels(),
            },
            default: this.getAllowedPriceModels()[0],
          },
          {
            id: 2,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.onePrice.hint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
          {
            id: 3,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.volumePricing.hint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.VOLUME_PRICING,
              },
            ],
          },
          {
            id: 4,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.volumePricingAndOptions.hint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
              },
            ],
          },
          {
            id: 5,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.onePriceForEachCurrency.hint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
              },
            ],
          },
          {
            id: 6,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.SKU,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.productSku,
            placeholder: localisationRegistry.productsOld.form.skuPlaceholder,
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
            status: state.statuses[EFieldName.SKU] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 7,
            type: EFormFieldType.INPUT_WITH_SELECT,
            fieldName: EFieldName.PRICE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.pricing,
            config: {
              selectConfig: {
                list: currencyList,
              },
              inputConfig: {
                mask: {
                  mask: 'NumberConstructor',
                  min: 0.01,
                  scale: 2,
                  radix: delimiter,
                },
                placeholder: this.getOnePricePlaceholder(),
                status: state.statuses[EFieldName.PRICE] ?? {
                  code: EValidationFieldStatusCode.DEFAULT,
                },
              },
              mode: ECInputWithSelectMode.MONOLITH,
            },
            default: {
              inputValue: '',
              selectValue: currencyList[0] ?? {},
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
          {
            id: 7,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.pricingHint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
          },
          {
            id: 8,
            type: EFormFieldType.PRICING_TABLE,
            fieldName: EFieldName.PRICE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.pricing,
            config: {
              pricingModel: formData.pricingModel.id,
              renewal: false,
            },
            conditionsList: [
              {
                type: EConditionType.NOT_EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
        ],
      },
      {
        type: EFormFieldType.DELIMITER,
        items: [],
      },
      {
        items: [
          {
            id: 1,
            label: localisationRegistry.productsOld.form.freeTrialPeriod.title,
            tooltip: localisationRegistry.productsOld.form.freeTrialPeriod.tooltip,
            type: EFormFieldType.SWITCHER,
            fieldName: EFieldName.TRIAL,
            fieldBlock: undefined,
            switcher: {
              revert: true,
              withHeader: true,
            },
          },
          ...freeTrialPeriodHint,
          {
            id: 3,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.TRIAL_SKU,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.productSku,
            placeholder: localisationRegistry.productsOld.form.skuPlaceholder,
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.TRIAL,
                value: true,
              },
            ],
            status: state.statuses[EFieldName.TRIAL_SKU] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 4,
            type: EFormFieldType.INPUT_WITH_SELECT,
            fieldName: EFieldName.TRIAL_TERM,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.termButton,
            tooltip: localisationRegistry.tooltip.product.form.term,
            config: this.getParamsConfig(formData.trialTerm?.inputValue ?? 1, EFieldName.TRIAL_TERM),
            default: {
              inputValue: 1,
              selectValue: moduleProduct.termService.list(1)
                .find((item) => item.id === ECalendarSymbol.M),
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.TRIAL,
                value: true,
              },
            ],
          },
          {
            id: 5,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.TRIAL_TITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.title,
            placeholder: localisationRegistry.productsOld.form.titlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.title,
            status: state.statuses[EFieldName.TRIAL_TITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.TRIAL,
                value: true,
              },
            ],
          },
          {
            id: 6,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.TRIAL_SUBTITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.subtitle,
            placeholder: localisationRegistry.productsOld.form.subtitlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.subtitle,
            status: state.statuses[EFieldName.TRIAL_SUBTITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.TRIAL,
                value: true,
              },
            ],
          },
        ],
      },
      {
        type: EFormFieldType.DELIMITER,
        items: [],
      },
      {
        items: [
          {
            id: 1,
            label: localisationRegistry.productsOld.form.renewal.title,
            tooltip: localisationRegistry.productsOld.form.renewal.tooltip,
            type: EFormFieldType.SWITCHER,
            fieldName: EFieldName.RENEWAL,
            fieldBlock: undefined,
            switcher: {
              revert: true,
              withHeader: true,
            },
          },
          {
            id: 2,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.RENEWAL_SKU,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.productSku,
            placeholder: localisationRegistry.productsOld.form.skuPlaceholder,
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
            status: state.statuses[EFieldName.RENEWAL_SKU] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
          },
          {
            id: 3,
            type: EFormFieldType.INPUT_WITH_SELECT,
            fieldName: EFieldName.RENEWAL_TERM,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.termButton,
            tooltip: localisationRegistry.tooltip.product.form.term,
            config: this.getParamsConfig(formData.renewalTerm?.inputValue ?? 1, EFieldName.RENEWAL_TERM),
            default: {
              inputValue: 1,
              selectValue: moduleProduct.termService.list(1)
                .find((item) => item.id === ECalendarSymbol.M),
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
            ],
          },
          {
            id: 3,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.RENEWAL_TITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.title,
            placeholder: localisationRegistry.productsOld.form.titlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.title,
            status: state.statuses[EFieldName.RENEWAL_TITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
            ],
          },
          {
            id: 4,
            type: EFormFieldType.INPUT,
            fieldName: EFieldName.RENEWAL_SUBTITLE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.subtitle,
            placeholder: localisationRegistry.productsOld.form.subtitlePlaceholder,
            tooltip: localisationRegistry.tooltip.product.form.subtitle,
            status: state.statuses[EFieldName.RENEWAL_SUBTITLE] ?? {
              code: EValidationFieldStatusCode.DEFAULT,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
            ],
          },
          {
            id: 5,
            type: EFormFieldType.INPUT_WITH_SELECT,
            fieldName: EFieldName.RENEWAL_PRICE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.pricing,
            config: {
              selectConfig: {
                list: currencyList,
              },
              inputConfig: {
                mask: {
                  mask: 'NumberConstructor',
                  min: 0.01,
                  scale: 2,
                  radix: delimiter,
                },
                placeholder: this.getOnePricePlaceholder(),
                status: state.statuses[EFieldName.RENEWAL_PRICE] ?? {
                  code: EValidationFieldStatusCode.DEFAULT,
                },
              },
              mode: ECInputWithSelectMode.MONOLITH,
            },
            default: {
              inputValue: '',
              selectValue: currencyList[0] ?? {},
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
          {
            id: 7,
            type: EFormFieldType.INFO_BLOCK,
            fieldName: EFieldName.TITLE,
            fieldBlock: undefined,
            config: {
              text: localisationRegistry.productsOld.form.pricingHint,
              variant: EColorScheme.PRIMARY,
              disableIcon: true,
              allowHtml: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
              {
                type: EConditionType.EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
          {
            id: 8,
            type: EFormFieldType.PRICING_TABLE,
            fieldName: EFieldName.RENEWAL_PRICE,
            fieldBlock: undefined,
            label: localisationRegistry.productsOld.form.pricing,
            config: {
              pricingModel: formData.pricingModel.id,
              renewal: true,
            },
            conditionsList: [
              {
                type: EConditionType.EQUALS,
                field: EFieldName.RENEWAL,
                value: true,
              },
              {
                type: EConditionType.NOT_EQUALS,
                field: 'pricingModel.id',
                value: EPricingModelName.ONE_PRICE,
              },
            ],
          },
        ],
      },
    ];
  };

  private getOnePricePlaceholder = () => {
    const numberFormatService = this.serviceContainer.numberFormatService;
    const delimiter = numberFormatService.getMoneyDelimiter();

    return `0${delimiter}00`;
  };

  update = (
    formConfig: IFormConfigBlock<EFieldName, undefined>[],
  ): IFormConfigBlock<EFieldName, undefined>[] => {
    const utilsService = this.serviceContainer.utilsService;
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();

    state.formConfig = utilsService.data.cloneImmutable(formConfig);

    return state.formConfig;
  };

  private getParamsConfig = (value, fieldName: EFieldName): ICInputWithSelectListValueConfig => {
    const state = this.serviceContainer.moduleProduct.productFormPage.getState();
    const moduleProduct = this.serviceContainer.moduleProduct;

    return {
      mode: ECInputWithSelectMode.HORIZONTAL,
      selectConfig: {
        list: moduleProduct.termService.list(value),
        status: {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
      inputConfig: {
        inputDelay: 300,
        status: state.statuses[fieldName] ?? {
          code: EValidationFieldStatusCode.DEFAULT,
        },
      },
    };
  };

  private getAllowedPriceModels = (): TPricingModelOption[] => {
    const pricingModelService = this.serviceContainer.moduleProduct.pricingModelService;
    const portalMode = this.serviceContainer.portalModeService;

    const map = {
      [EPriceType.ONE_PRICE]: EPricingModelName.ONE_PRICE,
      [EPriceType.VOLUME_PRICE]: EPricingModelName.VOLUME_PRICING,
      [EPriceType.ONE_PRICE_EACH_CURRENCY]: EPricingModelName.ONE_PRICE_FOR_EACH_CURRENCY,
      [EPriceType.VOLUME_PRICE_AND_OPTIONS]: EPricingModelName.VOLUME_PRICING_AND_OPTIONS,
    };

    const list = pricingModelService.list();
    const allowedPricingModels = portalMode.getProductFormPageHandler().getAllowedPricingModes()
      .map((pricingModel) => map[pricingModel]);

    return list.filter((pricingModel) => allowedPricingModels.includes(pricingModel.id));
  };
}

export default FormConfigHandler;
